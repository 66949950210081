<template lang="pug">
.Home
  LoadingFSFB(v-if="preload || preloadPayments")
  SelectZone(v-if="mode === 'SelectZone'" @changeMode="changeMode")
  Services(v-if="mode === 'Services'" @changeMode="changeMode")
  Login(v-show="mode === 'Login'" ref="loginComponent" @modeBack="modeBack"  @changeMode="changeMode")
  CurrentUser(v-if="mode === 'CurrentUser'")
</template>

<script>
import { mapState, mapActions } from "vuex";
var CryptoJS = require("crypto-js");

export default {
  name: "Home",

  data() {
    return {
      mode: "SelectZone",
      preload: false,
      userEmbebed: null
    };
  },

  components: {
    Login: () => import("./components/Login.vue"),
    CurrentUser: () => import("./components/CurrentUser.vue"),
    SelectZone: () => import("./components/SelectZone.vue"),
    Services: () => import("./components/Services.vue"),
    LoadingFSFB: () => import("./components/LoadingFSFB.vue")
  },

  async mounted() {
    try {
      if (this.loadApi) {
        await this.getStarted();
      }
    } catch (error) {
      console.error(error);
      this.goToView({
        view: "Error",
        actionError: this.getStarted,
        fromError: "Home"
      });
    }
  },

  watch: {
    $route() {
      if (this.mode && this.mode === "Login") {
        this.evalSite(this.sites);
      }
    }
  },

  computed: {
    ...mapState({
      currentUser: state => state.mobileFSFB.currentUser,
      sites: state => state.mobileFSFB.sites,
      convertDocuments: state => state.mobileFSFB.convertDocuments,
      preloadPayments: state => state.mobileFSFB.preloadPayments,
      loadApi: state => state.mobileFSFB.loadApi
    })
  },

  methods: {
    ...mapActions({
      setEmbebedData: "mobileFSFB/setEmbebedData",
      sendMessageForPay: "virtualrowFSFB/sendMessageForPay",
      handleMobileAppointments: "mobileFSFB/handleMobileAppointments",
      goToView: "mobileFSFB/goToView",
      checkMessageStatus: "virtualrowFSFB/checkMessageStatus",
      authAndQuery: "mobileFSFB/authAndQuery",
      handlePreloadAppointments: "mobileFSFB/handlePreloadAppointments",
      setSede: "mobileFSFB/setSede",
      setCustomArea: "mobileFSFB/setCustomArea",
      getMenus: "mobileFSFB/getMenus",
      setFromQR: "mobileFSFB/setFromQR"
    }),

    evalSite(val) {
      if (val && val.length) {
        const siteByCode = val.find(
          site => +site.code === +this.$route.params.code
        );

        for (const sbcItem of Object.entries(siteByCode.customAreas)) {
          if (sbcItem[0] === this.$route.query.area) {
            this.$store.commit("mobileFSFB/setState", {
              key: "selectedArea",
              value: sbcItem[1]
            });
            break;
          }
        }
      }
    },

    async getStarted() {
      // check sede
      let sede = this.$route.params;
      this.setSede(sede?.code);
      if (sede.code) {
        this.preload = true;

        await new Promise(r => {
          let int = setInterval(() => {
            if (this.sites.length > 0) {
              clearInterval(int);
              r();
            }
          }, 1000);
        });

        // check area
        let site = this.sites.find(s => s.code == sede.code);

        let area = this.$route.query.area;
        if (area) {
          if (site.customAreas[area]) {
            this.mode = "Login";
          } else {
            this.mode = "Services";
          }
        } else {
          this.mode = "SelectZone";
        }

        this.preload = false;
      }

      // Check for payments
      let queryS = this.$route.query;
      if (queryS.id_cita_pago && !queryS.status_transaction) {
        // preload
        this.handlePreloadAppointments(true);
        let resp = {};

        // mark as read the message
        if (queryS.row_id) {
          // is from QR
          this.setFromQR(true);

          resp = await this.sendMessageForPay({
            ...queryS,
            id: queryS.row_id
          });
        } else {
          // is from MOBILE
          this.setFromQR(false);

          // Fetch appointment
          resp;
          await new Promise(resolve => {
            // retry if request fail
            let intervalCheck = setInterval(async () => {
              resp = await this.checkMessageStatus({
                id_cita_pago: queryS.id_cita_pago
              });
              if (resp) {
                resp.data = resp.data ? resp.data[0] : {};
                clearInterval(intervalCheck);
                resolve();
              }
            }, 3000);
          });
        }

        // Fetch appointment
        await this.handleMobileAppointments({
          doc: resp.data?.user_document,
          tdoc: {
            code: resp.data?.docType
          }
        });

        // preload
        this.handlePreloadAppointments(false);

        // go to appointment
        this.goToView({ view: "Payment" });
      }

      // Fetch from redirect pay response
      if (queryS.status_transaction && queryS.reference_transaction) {
        // preload
        this.handlePreloadAppointments(true);

        // Fetch appointment
        let pay;
        await new Promise(resolve => {
          // retry if request fail
          let intervalCheck = setInterval(async () => {
            pay = await this.checkMessageStatus({
              id_cita_pago: queryS.reference_transaction.replace("appo-", "")
            });
            if (pay) {
              clearInterval(intervalCheck);
              resolve();
            }
          }, 3000);
        });

        if (pay.data && pay.data.length > 0) {
          this.setCustomArea(pay.data[0].area);
          await this.getMenus({
            code: this.$route.params.code,
            area: pay.data[0].area
          });

          await this.authAndQuery({
            NumeroIdentificacion: pay.data[0].user_document,
            TipoIdentificacion: this.convertDocuments[pay.data[0].docType]
          });

          // Fetch appointment
          await this.handleMobileAppointments({
            doc: pay.data[0].user_document,
            tdoc: {
              code: pay.data[0].docType
            }
          });

          // preload
          this.handlePreloadAppointments(false);

          // go to appointment
          this.goToView({ view: "Payment" });
        }
      }
    },

    validateAuth() {
      if (this.currentUser && this.currentUser.tipo_documento) {
        this.mode = "CurrentUser";
      }
    },

    modeBack() {
      this.mode = "Services";
    },

    decodeData(encrypted) {
      try {
        return JSON.parse(
          CryptoJS.AES.decrypt(atob(encrypted), "1FA6B99564ECD65C").toString(
            CryptoJS.enc.Utf8
          )
        );
      } catch (error) {
        console.log(error);
        return {};
      }
    },

    async changeMode(value) {
      if (!this.userEmbebed) {
        // Check embebed data
        let embebedData = this.$route.query.embebedData;
        if (embebedData) {
          this.setEmbebedData(embebedData);

          // Decode data
          let user = this.decodeData(embebedData);

          // Set data on login
          if (this.$refs.loginComponent && user) {
            this.userEmbebed = user;
            this.$refs.loginComponent.models.TipoIdentificacion = this.convertDocuments[
              user.patientDocumentType
            ];
            this.$refs.loginComponent.models.NumeroIdentificacion = String(
              user.patientDocument
            );
          }
        }
      }

      if (value == "Login" && this.userEmbebed) {
        this.preload = true;
        this.mode = "SelectZone";
        await this.$refs.loginComponent.onContinue();
        this.preload = false;
      }
      this.mode = value;
    },

    sedes() {
      return this.sites.map(s => {
        return { text: s.name, value: s.code };
      });
    }
  }
};
</script>

<style lang="scss">
.Home {
  height: 100%;
  overflow-y: auto;
  background-color: var(--color-bg-dark);
}
</style>
